import * as Icons from "../components/icons/Icons";

export const ICONS = Object.freeze({
  PIN: "pin",
  SWITCH_VERTICAL: "switch-vertical",
  REFRESH: "refresh",
  MINUS_CIRCLE: "minus-circle",
  SLASH_CIRCLE: "slash-circle",
  ARROW_UP: "arrow-up",
  ARROW_DOWN: "arrow-down",
  ARROW_LEFT: "arrow-left",
  ARROW_RIGHT: "arrow-right",
  EYE_OFF: "eye-off",
  MOVE: "move",
  EYE: "eye",
  SHOPPING_CART: "shopping-cart",
  SHOPPING_BAG: "shopping-bag",
  LINK: "link",
  INFO_CIRCLE: "info-circle",
  HELP_CIRCLE: "help-circle",
  CALENDER: "calender",
  REFRESH_COUNTER_CLOCKWISE: "refresh-counter-clockwise",
  X_CLOSE: "x-close",
  CHECK: "check",
  PLUS_CIRCLE: "plus-circle",
  HASH: "hash",
  SHUFFLE: "shuffle",
  GRID: "grid",
  TRENDING_UP: "trending-up",
  FRAME: "frame",
  COPY: "copy",
  DOT_HORIZONTAL: "dot-horizontal",
  EDIT: "edit",
  TRASH: "trash",
  FILE_DOWNLOAD: "file-download",
  SLIDERS: "sliders",
  EXCHANGE: "exchange",
  BELL: "bell",
  CLOCK_REWIND: "clock-rewind",
  COG: "cog",
  FLIP_FORWARD: "flip-forward",
  FLIP_BACKWARD: "flip-backward",
  FILTER_FUNNEL: "filter-funnel",
  LAB_TEST_TUBE: "lab-test-tube",
  BOOK_OPEN: "book-open",
  DATABASE: "database",
  PLUS: "plus",
  CONTRAST: "contrast",
  LOCK: "lock",
  LOCK_UNLOCKED: "lock-unlocked",
  CODE: "code",
  USER: "user",
  DATAFLOW: "dataflow",
  ROWS: "rows",
  DOUBLE_TICK: "double-tick",
  BAR_CHART: "bar-chart",
  ALERT_TRIANGLE: "alert-triangle",
  FILE_SEARCH: "file-search",
  GRID_2: "grid-2",
  DOCUMENT_OPEN: "document-open",
  ALARM_CLOCK_CHECK: "alarm-clock-check",
  GIT_GRAPH: "git-graph",
  SEARCH: "search",
  SWITCH_HORIZONTAL: "switch-horizontal",
  CHECK_CIRCLE_BROKEN: "check-circle-broken",
  CHEVRON_DOWN_DOUBLE: "chevron-down-double",
  LOG_IN: "log-in",
  LOG_OUT: "log-out",
  SHARE: "share",
  IMAGE: "image",
  UNCONNECTED_GRID: "unconnected-grid",
  LIGHTNING: "lightning",
  LANGUAGE: "language",
  EQUAL: "equal",
  CHEVRON_LEFT: "chevron-left",
  CHEVRON_RIGHT: "chevron-right",
  CHEVRON_SELECTOR_HORIZONTAL: "chevron-selector-horizontal",
  CHEVRON_DOWN: "chevron-down",
  BAR_CHART_SQUARE: "bar-chart-square",
  ALERT_CIRCLE: "alert-circle",
  LINE_CHART_UP: "line-chart-up",
  FILE: "file",
  SETTING: "setting",
  SELECTOR_VERTICAL_OPEN: "selector-vertical-open",
  SELECTOR_VERTICAL_CLOSE: "selector-vertical-close",
  ARROWS_UP: "arrows-up",
  FILTER_LINES: "filter-lines",
  PIN_TILTED: "pin-tilted",
  UN_PIN: "un-pin",
  DEMOTE: "demote",
  DELETE: "delete",
  DRAG: "drag",
  ROUNDED_GRID: "rounded-grid",
  ALIGN_JUSTIFY: "align-justify",
  ARROW_NARROW_UP_RIGHT: "arrow-narrow-up-right",
  MESSAGE_TEXT_SQUARE: "message-text-square",
  CHEVRON_UP: "chevron-up",
  CLOCK: "clock",
  ARROW_NARROW_DOWN: "arrow-narrow-down",
  ASCENDING: "ascending",
  DESCENDING: "descending",
  ARROW_NARROW_UP: "arrow-narrow-up",
  DOT_VERTICAL: "dot-vertical",
  STARS: "stars",
  BEAKER: "beaker",
  DOWNLOAD: "download",
  FILE_PLUS: "file-plus",
  X_CIRCLE: "x-circle",
});

export const ICONS_SVG_MAP = {
  [ICONS.PIN]: Icons.Pin,
  [ICONS.SWITCH_VERTICAL]: Icons.SwitchVertical,
  [ICONS.REFRESH]: Icons.Refresh,
  [ICONS.MINUS_CIRCLE]: Icons.MinusCircle,
  [ICONS.SLASH_CIRCLE]: Icons.SlashCircle,
  [ICONS.ARROW_UP]: Icons.ArrowUp,
  [ICONS.ARROW_DOWN]: Icons.ArrowDown,
  [ICONS.ARROW_LEFT]: Icons.ArrowLeft,
  [ICONS.ARROW_RIGHT]: Icons.ArrowRight,
  [ICONS.EYE_OFF]: Icons.EyeOff,
  [ICONS.MOVE]: Icons.Move,
  [ICONS.EYE]: Icons.Eye,
  [ICONS.SHOPPING_CART]: Icons.shoppingCart,
  [ICONS.SHOPPING_BAG]: Icons.ShoppingBag,
  [ICONS.LINK]: Icons.Link,
  [ICONS.INFO_CIRCLE]: Icons.InfoCircle,
  [ICONS.HELP_CIRCLE]: Icons.HelpCircle,
  [ICONS.CALENDER]: Icons.Calender,
  [ICONS.REFRESH_COUNTER_CLOCKWISE]: Icons.RefreshCounterClockwise,
  [ICONS.X_CLOSE]: Icons.XClose,
  [ICONS.CHECK]: Icons.Check,
  [ICONS.PLUS_CIRCLE]: Icons.PlusCircle,
  [ICONS.HASH]: Icons.Hash,
  [ICONS.SHUFFLE]: Icons.Shuffle,
  [ICONS.GRID]: Icons.Grid,
  [ICONS.TRENDING_UP]: Icons.TrendingUp,
  [ICONS.COPY]: Icons.Copy,
  [ICONS.DOT_HORIZONTAL]: Icons.DotHorizontal,
  [ICONS.FRAME]: Icons.Frame,
  [ICONS.EDIT]: Icons.Edit,
  [ICONS.TRASH]: Icons.Trash,
  [ICONS.FILE_DOWNLOAD]: Icons.FileDownload,
  [ICONS.SLIDERS]: Icons.Sliders,
  [ICONS.EXCHANGE]: Icons.Exchange,
  [ICONS.BELL]: Icons.Bell,
  [ICONS.CLOCK_REWIND]: Icons.ClockRewind,
  [ICONS.COG]: Icons.Cog,
  [ICONS.FLIP_FORWARD]: Icons.FlipForward,
  [ICONS.FLIP_BACKWARD]: Icons.FlipBackward,
  [ICONS.FILTER_FUNNEL]: Icons.FilterFunnel,
  [ICONS.LAB_TEST_TUBE]: Icons.LabTestTube,
  [ICONS.BOOK_OPEN]: Icons.BookOpen,
  [ICONS.DATABASE]: Icons.Database,
  [ICONS.PLUS]: Icons.Plus,
  [ICONS.CONTRAST]: Icons.Contrast,
  [ICONS.LOCK_UNLOCKED]: Icons.LockUnlocked,
  [ICONS.CODE]: Icons.Code,
  [ICONS.USER]: Icons.User,
  [ICONS.DATAFLOW]: Icons.Dataflow,
  [ICONS.ROWS]: Icons.Rows,
  [ICONS.DOUBLE_TICK]: Icons.DoubleTick,
  [ICONS.BAR_CHART]: Icons.BarChart,
  [ICONS.ALERT_TRIANGLE]: Icons.AlertTriangle,
  [ICONS.FILE_SEARCH]: Icons.FileSearch,
  [ICONS.GRID_2]: Icons.Grid2,
  [ICONS.LOCK]: Icons.Lock,
  [ICONS.DOCUMENT_OPEN]: Icons.DocumentOpen,
  [ICONS.ALARM_CLOCK_CHECK]: Icons.AlarmClockCheck,
  [ICONS.GIT_GRAPH]: Icons.GitGraph,
  [ICONS.SEARCH]: Icons.Search,
  [ICONS.SWITCH_HORIZONTAL]: Icons.SwitchHorizontal,
  [ICONS.CHECK_CIRCLE_BROKEN]: Icons.CheckCircleBroken,
  [ICONS.CHEVRON_DOWN_DOUBLE]: Icons.ChevronDownDouble,
  [ICONS.LOG_IN]: Icons.LogIn,
  [ICONS.LOG_OUT]: Icons.LogOut,
  [ICONS.SHARE]: Icons.Share,
  [ICONS.IMAGE]: Icons.Image,
  [ICONS.UNCONNECTED_GRID]: Icons.UnconnectedGrid,
  [ICONS.LANGUAGE]: Icons.Language,
  [ICONS.LIGHTNING]: Icons.Lightning,
  [ICONS.EQUAL]: Icons.Equal,
  [ICONS.CHEVRON_LEFT]: Icons.ChevronLeft,
  [ICONS.CHEVRON_RIGHT]: Icons.ChevronRight,
  [ICONS.CHEVRON_SELECTOR_HORIZONTAL]: Icons.ChevronSelectorHorizontal,
  [ICONS.CHEVRON_DOWN]: Icons.ChevronDown,
  [ICONS.BAR_CHART_SQUARE]: Icons.BarChartSquare,
  [ICONS.ALERT_CIRCLE]: Icons.AlertCircle,
  [ICONS.LINE_CHART_UP]: Icons.LineChartUp,
  [ICONS.FILE]: Icons.File,
  [ICONS.SETTING]: Icons.Setting,
  [ICONS.SELECTOR_VERTICAL_OPEN]: Icons.SelectorVerticalOpen,
  [ICONS.SELECTOR_VERTICAL_CLOSE]: Icons.SelectorVerticalClose,
  [ICONS.ARROWS_UP]: Icons.ArrowsUp,
  [ICONS.FILTER_LINES]: Icons.FilterLines,
  [ICONS.PIN_TILTED]: Icons.PinTilted,
  [ICONS.UN_PIN]: Icons.UnPin,
  [ICONS.DEMOTE]: Icons.Demote,
  [ICONS.DELETE]: Icons.Delete,
  [ICONS.DRAG]: Icons.Drag,
  [ICONS.ROUNDED_GRID]: Icons.RoundedGrid,
  [ICONS.ALIGN_JUSTIFY]: Icons.AlignJustify,
  [ICONS.ARROW_NARROW_UP_RIGHT]: Icons.ArrowNarrowUpRight,
  [ICONS.MESSAGE_TEXT_SQUARE]: Icons.MessageTextSquare,
  [ICONS.CHEVRON_UP]: Icons.ChevronUp,
  [ICONS.CLOCK]: Icons.Clock,
  [ICONS.ARROW_NARROW_DOWN]: Icons.ArrowNarrowDown,
  [ICONS.ASCENDING]: Icons.Ascending,
  [ICONS.DESCENDING]: Icons.Descending,
  [ICONS.ARROW_NARROW_UP]: Icons.ArrowNarrowUp,
  [ICONS.DOT_VERTICAL]: Icons.DotVertical,
  [ICONS.STARS]: Icons.Stars,
  [ICONS.BEAKER]: Icons.Beaker,
  [ICONS.DOWNLOAD]: Icons.Download,
  [ICONS.FILE_PLUS]: Icons.FilePlus,
  [ICONS.X_CIRCLE]: Icons.XCircle,
}