import React, { useEffect, useState, useCallback, Fragment } from "react";
import { Classes, Button, Dialog, Icon, OverlayToaster } from "@blueprintjs/core";
import { FlexContainer } from "../styles/styled";
import useAppData from "../hooks/useAppData";
import SocketConsumer from "../SocketConsumer"
import useAuthorisationData from "../hooks/useAuthorisationData";
import TagalysIcon from "./icons/TagalysIcon";
import { ICONS } from "../utils/icons";

const toaster = OverlayToaster.create({
  position: 'top'
})

export default function RequireAuthenticationDialog(props) {
  const [detail, setDetail] = useState({});
  const { session_channel } = useAppData("socket_channels")
  const { url: authorization_url} = useAppData("authorization")

  const { isDialogOpen, toggleDialogState: setDialogState } = useAuthorisationData()

  useEffect(() => {
    document.addEventListener("forbidden_error", handleForbiddenError);
  }, []);

  const openDialog = () => {
    setDialogState(true);
  };

  const closeDialog = () => {
    setDialogState(false);
  };

  const handleForbiddenError = (evt) => {
    openDialog();
    setDetail(evt.detail);
  };

  const handleDialogClose = useCallback(async () => {
    if (detail.callback) {
      await detail.callback()
    }
    toaster.show({
      message: "Thank you for authenticating. You may now proceed with your session.",
      intent: "success",
      icon: "tick"
    })
  }, [detail])

  return (
    <Fragment>
     <SocketConsumer
        channel={session_channel}
        event={"updated/authenticated_at"}
        onNotificationRecieved={(data) => {
          closeDialog()
        }}
      />
      <Dialog
        className="bg-white"
        isOpen={isDialogOpen}
        onClosing={handleDialogClose}
      >
        <div className={Classes.DIALOG_BODY}>
          <FlexContainer>
            <TagalysIcon className={"mr-sm"} icon={ICONS.INFO_CIRCLE} size={20}/>
            <h4>Please log in again to access this secured section.</h4>
          </FlexContainer>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              icon="log-in"
              intent={"success"}
              onClick={() => window.open(authorization_url, "_blank")}
            >
              Log in
            </Button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
}
