import React, { memo, Fragment, useEffect } from "react";
import { OverlayToaster, Spinner, Icon, Button, Collapse, Alert, Checkbox, Intent } from "@blueprintjs/core";
import { apiGet, apiPost } from '../../shared/api'
import { useImmer } from 'use-immer';
import { resolveParams } from "../utils";
import { Heading, Description, FlexColumn, CardItem, DeviceDetails, SessionHeader, SpinnerContainer } from "./StyledComponents";
import moment from "moment";
import CardTable from "./CardTable";
import Actions from "../../shared/components/Actions";
import ResourceDestroyAlert from "../../shared/components/ResourceDestroyAlert";
import Pill from "../../shared/components/Pill";
import { SIZE } from "../../shared/utils/constants";

const userToast = OverlayToaster.create({
  position: 'top'
})

const getParams = (state) => {
  const { deactivateSessions } = state
  const params = { deactivate_current_session: deactivateSessions.current }
  return params
}


const Sessions = ({ sessions, renderProps, currentSessionId}) => {
  return sessions.map((session) => {
    const isCurrentSession = (session.session_id === currentSessionId)
    const { remember_me, remember_till } = session.remember_me_details
    // Need to show the remember_me details for active rememberable session and remember_till duration should be more than the current time
    const showRememberMeDetail = (session.active && remember_me && moment(remember_till).isAfter())
    return (
      <CardItem key={session.session_id}>
        <FlexColumn>
          <DeviceDetails>
            <Icon
              icon={session.mobile === true ? 'mobile-phone' : 'desktop'}
              iconSize='20px'
              intent='none'
              className="pt-5"
            />
            <span className="sub-content pl-10">
              {session.browser}
            </span>
            <span className="sub-content pl-5 pr-5">
              ({session.os})
            </span>
            {isCurrentSession &&
              <Pill intent={Intent.SUCCESS} size={SIZE.S} text={"Current session"}/>
            }
           {showRememberMeDetail &&
              <Pill className="ml-5" size={SIZE.S} text={`Expires ${moment(remember_till).fromNow()}`}/>
            }
          </DeviceDetails>
          <div className="description pl-30">
            Last active: {moment(session.accessed_at).fromNow()}
          </div>
        </FlexColumn>
        {renderProps && renderProps(session)}
      </CardItem>
    )
  })
}

export default memo(function SessionTab() {
  let [state, setState] = useImmer({
    loading: false,
    sessions: {
      active: [],
      inactive: [],
    },
    deactivateSessions:{
      all: false,
      current: false
    },
    currentActiveSessionId: "",
    showInactiveSessions: false,
    showDeleteAlert: false,
    isSessionDeactivateAlertOpen: false,
    sessionToDeactivate: ""
  })

  useEffect(() => {
    handleInit()
  }, [])
  
  const {
    sessions,
    loading,
    currentActiveSessionId,
    showInactiveSessions,
    showDeleteAlert,
    deactivateSessions,
    isSessionDeactivateAlertOpen,
    sessionToDeactivate
  } = state
  
  const handleInit = async () => {
    setState(draft => { draft.loading = true; })
    const response = await apiGet("user_session/index", {})
    if (response) {
      setState(draft => {
        draft.sessions = response.sessions;
        draft.currentActiveSessionId = response.current_active_session_id;
      })
    }
    setState(draft => { draft.loading = false; })
  }
  const onClickMapping = (session) => {
    return {
      logout: () => {
        setState(draft => {
          draft.sessionToDeactivate = session;
          draft.isSessionDeactivateAlertOpen = true
        })
      }
    };
  };

  const deactivateUserSession = async () => {
    const actionObj = sessionToDeactivate.actions.overflow.filter(
      (action_obj) => action_obj.name === "logout"
    )[0];
    const response = await apiPost(actionObj.action.details.path, {});
        if (response) {
          userToast.show({
            message: "The session has been deactivated.",
            intent: 'success',
            timeout: 3000
          })
      handleInit()
    }
  }
  const handleDeactivateAllDevices = async () => {
    setState(draft => { draft.deactivateSessions.all = true })
    let params = getParams(state)
    const response = await apiPost('user_session/deactivate_all_user_sessions', params);
    if (response) {
      if (response.hasOwnProperty('redirect_path')) {
        window.location.href = `${window.location.origin}${response.redirect_path}`
        return
      }
      userToast.show({
        message: "Successfully logged out from all devices except your current session.",
        intent: 'success',
        timeout: 3000
      })
      handleInit()
    }
    setState(draft => {
      draft.showDeleteAlert = false;
      draft.deactivateSessions.all = false
    })
  }
  if (loading) {
    return (
      <SpinnerContainer>
        <Spinner size="50" />
      </SpinnerContainer>
    )
  }
  else {
    return (
      <Fragment>
        <Heading>Sessions</Heading>
        <Description>The devices where you are logged in are listed below. Log out from any device that you do not recognize.</Description>
        <SessionHeader className={`mb-xl mt-xl color-black-light`}>Active sessions</SessionHeader>
        <CardTable
          title='Device'
          rightElement={
            <Button
              intent='danger'
              minimal='true'
              onClick={() => { setState(draft => { draft.showDeleteAlert = true }) }}>
              Log out from all devices
            </Button>
          }
          footer={sessions.inactive.length > 0 && !showInactiveSessions &&
            <div className="p-md">
              <Button
                minimal='true'
                className="w-100"
                icon='chevron-down'
                onClick={() => { setState(draft => { draft.showInactiveSessions = true }) }}>
                See More
              </Button>
            </div>
          }
          body={
            <Sessions
              sessions={sessions.active}
              onClickMapping={onClickMapping}
              currentSessionId={currentActiveSessionId}
              renderProps={(session) => {
                const isCurrentSession = (session.session_id === currentActiveSessionId)
                return (!isCurrentSession &&
                  <Actions
                    actions={session.actions}
                    onClickMapping={onClickMapping(session)}
                  />)
                }
              }
            />
          }
        />
        <Collapse isOpen={showInactiveSessions}>
          <SessionHeader>Recent inactive sessions</SessionHeader>
          <CardTable
            title='Device'
            footer={
              <div className="p-10 ">
                <Button minimal='true'
                  className="w-100"
                  icon='chevron-up'
                  onClick={() => {
                    setState(draft => { draft.showInactiveSessions = false })
                  }}>
                  Hide
                </Button>
              </div>
            }
            body={
              <Sessions
                sessions={sessions.inactive}
              />
            }
          />
        </Collapse>
        <Alert
          className="bg-white"
          icon="log-out"
          loading={deactivateSessions.all}
          isOpen={showDeleteAlert}
          onCancel={() => { setState(draft => { draft.showDeleteAlert = false }) }}
          confirmButtonText="Log out"
          cancelButtonText="Cancel"
          intent="danger"
          onConfirm={() => { handleDeactivateAllDevices() }}
        >
          <h4 className="m-0 pb-10">Are you sure you want to log out of all devices?</h4>
          <Checkbox
            inline='true'
            checked={deactivateSessions.current}
            onChange={() => { setState(draft => { draft.deactivateSessions.current = !state.deactivateSessions.current }) }}
            label="Log out from my current session" />
        </Alert>
        <ResourceDestroyAlert
          icon="log-out"
          confirmButtonText="Log out"
          isOpen={isSessionDeactivateAlertOpen}
          handleCancel={() => setState((draftState) => {
            draftState.isSessionDeactivateAlertOpen = false;
            draftState.sessionToDeactivate = {}
          })}
          handleConfirm={() => {
            setState((draftState) => {
              draftState.isSessionDeactivateAlertOpen = false;
            })
            deactivateUserSession()
          }}
          header={"Are you sure you want to log out of the session?"}
        />
      </Fragment>
    )
  }
})