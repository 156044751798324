import React from "react";
import { Position } from "@blueprintjs/core";
import { TimezoneSelect } from "@blueprintjs/datetime";

const popoverProps = {
  minimal: false,
  position: Position.BOTTOM,
  popoverClassName: "single-select-popover",
  boundary: 'viewport'
};
export default React.memo(({ value, handleChange }) => {
  return (
    <TimezoneSelect
      onChange={handleChange}
      value={value}
      placeholder={value || 'UCT (UTC) +00:00'}
      valueDisplayFormat="composite"
      showLocalTimezone={true}
      popoverProps={popoverProps}
      className="timezone"
    />
  );
});
