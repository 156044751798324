import React, { Fragment, useRef, useEffect } from "react";
import ProfilePanel from "./components/ProfilePanel";
import PasswordPanel from "./components/PasswordTab";
import SessionPanel from "./components/SessionTab";
import AuthenticationPanel from "./components/AuthenticationTab";
import TwoFactorAuthPanel from "./components/TwoFactorAuthTab";
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import SettingsNav from "./components/SettingsNav";
import { InnerContainer } from "./components/StyledComponents";
import SecureAreasContainer from "../shared/components/SecureAreasContainer"
import './style.scss';
import '../shared/style.scss';

// REV_REM
export const getMinHeightOfSettingsContainer = () => {
  const tagalysNavElement = document.querySelector("#header").offsetHeight;
  const tagalysHeaderElement = document.querySelector(".page-header").offsetHeight;
  const container = document.querySelector(".container")
  const mainContainerPaddingTop = parseFloat(window.getComputedStyle(container, null).getPropertyValue("padding-top"))
  const mainContainerPaddingBottom = parseFloat(window.getComputedStyle(container, null).getPropertyValue("padding-bottom"))
  return (
    window.innerHeight - (tagalysNavElement + tagalysHeaderElement + mainContainerPaddingTop + mainContainerPaddingBottom)
  );
};

export default React.memo(() => {
  const containerRef = useRef(null)
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.minHeight = `${getMinHeightOfSettingsContainer()}px`
      containerRef.current.style.maxHeight = `${getMinHeightOfSettingsContainer()}px`
    }
  }, [])
  const { path } = useRouteMatch()
  return (
    <Fragment>
      <Switch>
        <Route path={[path]}>
          <div className={`settings-container`} ref={containerRef}>
            <SettingsNav />
            <InnerContainer>
              <SecureAreasContainer>
                <Route exact path={`${path}/profile`} component={ProfilePanel} />
                <Route exact path={`${path}/security/sessions`} component={SessionPanel} />
                <Route exact path={`${path}/security/change_password`} component={PasswordPanel} />
                <Route exact path={`${path}/security/authentication`} component={AuthenticationPanel} />
                <Route exact path={`${path}/security/two_factor_authentication`} component={TwoFactorAuthPanel} />
              </SecureAreasContainer>
            </InnerContainer>
          </div>
        </Route>
      </Switch>
    </Fragment>
  );
});
