import React, { Fragment, useEffect, useState } from 'react'
import { FormGroup, InputGroup, Button, OverlayToaster, Checkbox, Icon, Tooltip, Intent } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { useImmer } from 'use-immer'

import * as Styled from "../shared/styles/styled"
import "../shared/style.scss"
import { apiPost } from "../shared/api"
import useToggleState from '../shared/hooks/useToggleState'
import ThirdPartyAuthButtons from '../shared/components/ThirdPartyAuthButtons'
import Flash from '../shared/components/Flash'
import Dialog from '../shared/components/Dialog'
import TwoFactorScreen from './TwoFactorScreen'
import ReSignInPrompt from './ReSignInPrompt'
import EmailInput from '../shared/components/EmailInput'
import { testAttributeGenerator } from '../shared/utils/common'
import TrustDevice from './TrustDevice'

/*
  -> why evt.persist()
*/
const signInToaster = OverlayToaster.create({
  position: 'top'
})

const SignInForm = ({
  handleFirstFactorResponse,
  handleTwoFactorResponse,
  config
}) => {
  const {
    showTrustDevice = true,
    firstFactorPath = null,
    twoFactorPath = null,
    getParams = undefined,
    getOtpParams
  } = config
  const [formDetails, setFormDetails] = useImmer({
    email: "",
    password: "",
    trustDevice: false,
    errors: []
  })
  const [isDialogOpen, setDialogState] = useToggleState()
  const [loading, setLoading] = useToggleState()
  const [reSignInPromptDetails, setReSignInPromptDetails] = useImmer({
    showPrompt: false,
    details: {
      redirect_path: "",
      second_factor_method: ""
    }
  })

  const hasErrors = formDetails.errors.length

  const handleChangeInFormDetail = (evt) => {
    evt.persist()
    setFormDetails((draftState) => {
      const { id, value } = evt.target
      draftState[id] = value
      if (draftState.errors.length > 0) {
        draftState.errors = []
      }
    })
  }

  const clearEmailAndPassword = () => {
    setFormDetails((draftState) => {
      draftState.email = ""
      draftState.password = ""
    })
  }

  const onCheckBoxChange = (evt) => {
    const { id, checked, } = evt.target
    setFormDetails((draftState) => {
      draftState[id] = checked
    })
  }

  const handleCommonFormResponse = (response) => {
    if (response.toast) {
      signInToaster.show(response.toast)
    }
    if (response.two_factor_enabled) {
      clearEmailAndPassword()
      return setDialogState(true)
    }
    if (response.hasOwnProperty("errors")) {
      handleInvalidLoginAttempt(response.errors)
    }
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    setLoading(true)
    const response = await apiPost(firstFactorPath, getParams(formDetails), null, {
      rawResponse: true
    })
    try {
      const data = await response.json()
      if (data) {
        if (response.status === 401) {
          handleInvalidLoginAttempt([data.error])
        } else {
          handleFirstFactorResponse(data)
          handleCommonFormResponse(data)
        }
      }
      setLoading(false)
    }
    catch (e) {
      if (response.status === 422) {
        return window.location.href = `${window.location.origin}/users/sign_in`
      }
      signInToaster.show({
        message: "Unable to process your request. Please contact us at support@tagalys.com for further assistance.",
        intent: "danger"
      })
      setLoading(false)
    }
  }

  const handleInvalidLoginAttempt = (errors) => {
    signInToaster.show({
      message: errors.join("\n"),
      intent: "danger",
      timeout: 5000,
      icon: "error"
    })
    setLoading(false)
    setFormDetails((draftState) => {
      draftState.errors = errors
    })
  }

  const openReSignInPrompt = () => {
    setReSignInPromptDetails((draft) => {
      draft.showPrompt = true
    })
  }

  const closeReSignInPrompt = () => {
    setReSignInPromptDetails((draft) => {
      draft.showPrompt = false
    })
  }

  const setPromptDetails = (details) => {
    setReSignInPromptDetails((draft) => {
      draft.details = details
    })
  }

  return <div className={`sign-in-page`}>
    <Flash
      messages={formDetails.errors}
      intent={Intent.DANGER}
      className="mb-10"
    />
    <form>
      <EmailInput
        onChange={handleChangeInFormDetail}
        value={formDetails.email}
        hasErrors={hasErrors}
      />
      <FormGroup
        label={<Fragment>
          <span>Password &nbsp;</span>
          <Link tabIndex="-1" to="/users/password/new">(don't have one?)</Link>
        </Fragment>}
      >
        <InputGroup
          id="password"
          onChange={handleChangeInFormDetail}
          placeholder="Enter password"
          type="password"
          value={formDetails.password}
          intent={hasErrors ? "danger" : "none"}
          {...testAttributeGenerator("sign-in-details", "password")}
        />
      </FormGroup>
      {showTrustDevice &&
        <TrustDevice
          trustDevice={formDetails.trustDevice}
          onChange={onCheckBoxChange}
          className={`mb-md`}
        />
      }
      <Button
        type="submit"
        className="w-100"
        intent="success"
        loading={loading}
        onClick={handleSubmit}
        {...testAttributeGenerator("btn-cta", "sign_in")}
        {...(loading ? testAttributeGenerator("sign-in-details", "sign_in_loading") : {})}
      >
        Log in
      </Button>
      <div className="helper-text text-base mt-md">
        <Fragment>
          <Link to="/users/password/new">Forgot password?</Link>
          <Link className="mt-xs d-block" to="/users/unlock/new">Unlock your account</Link>
        </Fragment>
      </div>
    </form>
    <Dialog
      isOpen={isDialogOpen}
      onClose={() => {
        setDialogState(false)
      }}
      title="Two-Factor Authentication"
    >
      <TwoFactorScreen
        trustDevice={formDetails.trustDevice}
        twoFactorPath={twoFactorPath}
        handleTwoFactorResponse={(response) => {
          if (response.valid_otp_attempt === false) {
            setDialogState(false)
            setPromptDetails(response)
            return openReSignInPrompt()
          }
          handleTwoFactorResponse(response)
        }}
        getOtpParams={getOtpParams}
        showTrustDeviceCheckbox={showTrustDevice}
      />
    </Dialog>
    <ReSignInPrompt
      isOpen={reSignInPromptDetails.showPrompt}
      promptDetails={reSignInPromptDetails.details}
      onConfirm={closeReSignInPrompt}
    />
  </div>
}

const SignInDetails = (props) => {
  return (
    <div className="flex-1">
      <Styled.Heading>Log in to your account</Styled.Heading>
      <ThirdPartyAuthButtons
        containerStyle="flex-column sign-in-auth-actions-container"
        buttonStyle={`third-party-button-auth-button`}
      />
      <Styled.Seperator>Or</Styled.Seperator>
      <SignInForm {...props}/>
    </div>
  )
}

export default function SignIn(props) {
  return (
    <SignInDetails {...props} />
  )
}
