import styled from "styled-components";

export const Section = styled.main`
  padding: 16px;
  display: flex;
  justify-content: center;
  `
  
  export const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row" };
  `
  export const Container = styled.div`
  width: 400px;
  padding: 16px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #D0D5DD
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  `

export const Heading = styled.h3`
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin: 0 0 16px 0;
`

export const Seperator = styled.div`
  margin: 8px 0px 8px 0px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
`
const intentToColorMap = {
  "danger": "#C23030",
  "success": "#0D8050",
  "warning": "#BF7326"
}

export const PasswordMeter = styled.div`
  height: 100%;
  transition: width 400ms ease-in;
  width: ${(props) => `${props.width}%`};
  border: 2px solid;
  border-color: ${(props) => `${intentToColorMap[props.intent]}`};
  background: ${(props) => `${intentToColorMap[props.intent]}`};
`

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items:  ${(props) => props.alignItems || "center"};
`