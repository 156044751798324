import Styled from "styled-components";

export const Heading = Styled.h3`
  margin:  0 0 4px 0;
  font-size: 18px;
  color: #344054;
`
export const Description = Styled.div`
  color: #667085;
  font-size: 14px;
  display: flex;
  line-height: 18px;
  margin-bottom: 16px;
`
export const InnerContainer = Styled.div`
  width: 100%;
  overflow-y:auto;
  padding:0px 5px 5px 16px;
  border-left: 2px solid #D0D5DD;
  margin-left: -2px;
`
export const FlexColumn = Styled.div`
  display: flex;
  flex-direction: column;
`
export const CardItem = Styled.div`
  padding: 8px 16px;
  display:flex;
  align-items:center;
  justify-content: space-between;
  border-bottom: solid 1px #D0D5DD;
  &:last-child{
    border-bottom: none;
  }
`

export const DeviceDetails = Styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
`
export const SessionHeader = Styled.h4`
  margin: 16px 0px;
  color: #344054;
`
export const StepTitle = Styled.span`
  color: #344054;
  font-weight: 400;
  display: flex;
  font-size: 16px;
  margin: 4px 0px;
  white-space: nowrap;
`
export const StepDescription = Styled.span`
  color: #667085;
  display: block;
  padding-left: 8px;
  font-size: 14px;
  padding-bottom: 4px;
  line-height: 18px;
`
export const Step = Styled.div`
  display:flex;
  flex-direction: row;
  align-items: baseline;
`
export const SpinnerContainer = Styled.div`
  width: 100%;
  height:100%;
  display:flex
  align-items:center;
  justify-content:center;
`