import React from "react";
import { Checkbox, Tooltip } from "@blueprintjs/core";

import { FlexContainer } from "../shared/styles/styled"
import TagalysIcon from "../shared/components/icons/TagalysIcon";

import { ICONS } from "../shared/utils/icons";

export default function TrustDevice({
  trustDevice,
  onChange,
  className=""
}) {
  return (
    <FlexContainer className={className}>
      <Checkbox
        id="trustDevice"
        type="checkbox"
        checked={trustDevice}
        label="Trust this device"
        onChange={onChange}
      />
      {/* 2 weeks from user session - dynamically */}
      <Tooltip className="ml-5 mb-2" content="You will be logged in for two weeks and you will not be asked for password and OTP on this device until you log out on intent.">
        <TagalysIcon icon={ICONS.INFO_CIRCLE} />
      </Tooltip>
    </FlexContainer>
  );
}
